var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("base-toolbar", {
    attrs: { searchable: true, search: _vm.searchText },
    on: {
      "update:search": function ($event) {
        _vm.searchText = $event
      },
    },
    scopedSlots: _vm._u(
      [
        _vm.showFilter
          ? {
              key: "mobile-search-filters",
              fn: function () {
                return [
                  _c("search-filter", {
                    ref: "searchFilter",
                    attrs: {
                      filterCount: _vm.filterCount,
                      isFiltered: _vm.isFiltered,
                      clearFilter: _vm.clearFilter,
                      mobileMode: true,
                    },
                    on: { focusMenu: _vm.focusMenu },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "filters-menu",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-chip-group",
                                    {
                                      staticClass: "mx-auto",
                                      attrs: {
                                        multiple: "",
                                        "active-class":
                                          "accent3--text font-weight-bold",
                                        column: "",
                                      },
                                      model: {
                                        value: _vm.selectedFilters,
                                        callback: function ($$v) {
                                          _vm.selectedFilters = $$v
                                        },
                                        expression: "selectedFilters",
                                      },
                                    },
                                    _vm._l(
                                      _vm.filterItems,
                                      function (item, index) {
                                        return _c(
                                          "pb-chip",
                                          {
                                            key: `sy-fi-${item.value}`,
                                            ref:
                                              index == 0 ? "firstMenuItem" : "",
                                            refInFor: true,
                                            attrs: {
                                              filter: "",
                                              outlined: "",
                                              value: item.value,
                                            },
                                            on: {
                                              keyup: [
                                                function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "escape",
                                                      undefined,
                                                      $event.key,
                                                      undefined
                                                    )
                                                  )
                                                    return null
                                                  return _vm.focusButton.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                                function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  return _vm.arrayAddOrRemove(
                                                    _vm.selectedFilters,
                                                    item.value
                                                  )
                                                },
                                              ],
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.text)),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1777114548
                    ),
                  }),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: "toolbar-left",
          fn: function () {
            return [
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { md: "2", sm: "3" } },
                [
                  _c("search-filter", {
                    ref: "searchFilter",
                    attrs: {
                      filterCount: _vm.filterCount,
                      isFiltered: _vm.isFiltered,
                      clearFilter: _vm.clearFilter,
                      searchText: _vm.searchText,
                      searchWidthGrow: _vm.searchWidthGrow,
                      mobileMode: false,
                    },
                    on: {
                      "update:searchText": function ($event) {
                        _vm.searchText = $event
                      },
                      "update:search-text": function ($event) {
                        _vm.searchText = $event
                      },
                      focusMenu: _vm.focusMenu,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "filters-menu",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-chip-group",
                                  {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      multiple: "",
                                      "active-class":
                                        "accent3--text font-weight-bold",
                                      column: "",
                                    },
                                    model: {
                                      value: _vm.selectedFilters,
                                      callback: function ($$v) {
                                        _vm.selectedFilters = $$v
                                      },
                                      expression: "selectedFilters",
                                    },
                                  },
                                  _vm._l(
                                    _vm.filterItems,
                                    function (item, index) {
                                      return _c(
                                        "pb-chip",
                                        {
                                          key: `sy-fi-${item.value}`,
                                          ref:
                                            index == 0 ? "firstMenuItem" : "",
                                          refInFor: true,
                                          attrs: {
                                            filter: "",
                                            outlined: "",
                                            value: item.value,
                                          },
                                          on: {
                                            keyup: [
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "escape",
                                                    undefined,
                                                    $event.key,
                                                    undefined
                                                  )
                                                )
                                                  return null
                                                return _vm.focusButton.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.arrayAddOrRemove(
                                                  _vm.selectedFilters,
                                                  item.value
                                                )
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.text)),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm.$currentUser.isDistrictAdmin
                ? _c("v-autocomplete", {
                    staticClass: "ml-2",
                    staticStyle: { "max-width": "190px", "min-width": "190px" },
                    attrs: {
                      label: _vm.$t("schoolLabel"),
                      "display:inline-flex": "",
                      "hide-details": "auto",
                      items: _vm.schoolItems,
                      outlined: "",
                      dense: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function (data) {
                            return [
                              _c("span", [_vm._v(_vm._s(data.item.text))]),
                              _c("rostered-item-indicator", {
                                key: data.item.value + data.item.isItemRostered,
                                attrs: { itemType: "school", item: data.item },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      901267436
                    ),
                    model: {
                      value: _vm.currentSchoolId,
                      callback: function ($$v) {
                        _vm.currentSchoolId = $$v
                      },
                      expression: "currentSchoolId",
                    },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
        {
          key: "toolbar-right",
          fn: function () {
            return [
              !_vm.editMode
                ? _c(
                    "pb-btn",
                    {
                      staticClass: "ml-2 my-1",
                      attrs: {
                        color: "primary",
                        label: _vm.$t("addLabel") + " " + _vm.$t("yearLabel"),
                        text: "",
                        outlined: "",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.edit(null)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("fal fa-plus")])],
                    1
                  )
                : [
                    _vm.hasSelectedRows
                      ? _c(
                          "pb-btn",
                          {
                            staticClass: "ml-2 my-1",
                            attrs: {
                              color: "error",
                              label: _vm.$t("deleteLabel"),
                              text: "",
                              outlined: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.deleteSelectedSchoolYears.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "pb-btn",
                      {
                        staticClass: "ml-2 my-1",
                        attrs: {
                          color: "primary",
                          label: _vm.$t("doneLabel"),
                          text: "",
                          outlined: "",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.toggleEditMode.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("v-icon", { staticClass: "done-icon" }, [
                          _vm._v("fal fa-pen-slash"),
                        ]),
                      ],
                      1
                    ),
                  ],
              !_vm.editMode
                ? _c(
                    "v-menu",
                    {
                      attrs: {
                        bottom: "",
                        "offset-y": "",
                        "close-on-click": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on: onMenu }) {
                              return [
                                _c(
                                  "pb-btn",
                                  _vm._g(
                                    {
                                      staticClass: "ml-2 my-1",
                                      attrs: {
                                        color: "primary",
                                        label: _vm.$t("actionsLabel"),
                                        text: "",
                                        outlined: "",
                                      },
                                    },
                                    { ...onMenu }
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("fal fa-ellipsis-stroke-vertical"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        648777904
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            { on: { click: _vm.toggleEditMode } },
                            [
                              _c(
                                "v-btn",
                                { attrs: { text: "", color: "primary" } },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("fal fa-pen-to-square"),
                                  ]),
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v(_vm._s(_vm.$t("manageLabel"))),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }