
import BaseToolbar from '@/components/core/BaseToolbar.vue';
import SchoolYearsMixin from '@/mixins/school-years-mixin';
import CommonUtils from '@/utils/common-utils';
import { Component, Watch } from 'vue-property-decorator';
import RosteredItemIndicator from '@/components/integration/RosteredItemIndicator.vue';
import SearchFilter from '@/components/core/SearchFilter.vue';

  @Component({
    mixins: [SchoolYearsMixin],
    components: {
      BaseToolbar,
      RosteredItemIndicator,
      SearchFilter
    }
  })
export default class SchoolYearsToolbar extends BaseToolbar {
    editMode!: boolean;
    searchText!: string;
    hasSelectedRows!: boolean;
    localRefreshKey = CommonUtils.generateUUID();
    searchWidthGrow = '0';
    itemFocused = '';

    $refs!: {
      searchFilter: SearchFilter
    }

    @Watch('browserWidth')
    onBrowserWidthChanged() {
      this.localRefreshKey = CommonUtils.generateUUID();
    }

    @Watch('browserHeight')
    onBrowserHeightChanged() {
      this.localRefreshKey = CommonUtils.generateUUID();
    }

    get localSchoolYearsComponent(): any {
      return this.currentPageInstance || {};
    }

    get arrayAddOrRemove() {
      return CommonUtils.arrayAddOrRemove;
    }

    edit(value: any) {
      this.$eventBus.$emit('closeSubPage');
      this.localSchoolYearsComponent.edit(value);
    }

    get toggleEditMode() {
      return this.localSchoolYearsComponent.toggleEditMode || this.emptyFunction;
    }

    get deleteSelectedSchoolYears() {
      return this.localSchoolYearsComponent.deleteSelectedSchoolYears || this.emptyFunction;
    }

    get isFiltered() {
      if (this.localSchoolYearsComponent.isFiltered) {
        this.searchWidthGrow = '40';
      } else {
        this.searchWidthGrow = '0';
      }
      return this.localSchoolYearsComponent.isFiltered || false;
    }

    get filterCount() {
      return this.localSchoolYearsComponent.filteredCount || 0;
    }

    focusMenu() {
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 100)).then(() => {
          try {
            (this.$refs as any).firstMenuItem[0].$el.focus();
          } catch (e) {
            console.log(this.$t('focusFailed'));
          }
        });
      });
    }

    focusButton() {
      this.$refs.searchFilter.focusButton();
    }

    get clearFilter() {
      return this.localSchoolYearsComponent.resetFilter || this.emptyFunction;
    }

    get schoolItems() {
      return this.localSchoolYearsComponent.schoolItems || [];
    }

    get currentSchoolId() {
      return this.localSchoolYearsComponent.currentSchoolId;
    }

    set currentSchoolId(val: any) {
      this.localSchoolYearsComponent.currentSchoolId = val;
    }

    get selectedFilters() {
      return this.localSchoolYearsComponent.selectedFilters;
    }

    set selectedFilters(value: any) {
      this.localSchoolYearsComponent.selectedFilters = value;
    }

    get filterItems() {
      return this.localSchoolYearsComponent.filterItems || [];
    }

    get showFilter() {
      return this.localSchoolYearsComponent.showFilter;
    }
}
