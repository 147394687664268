



































































































































import BaseToolbar from '@/components/core/BaseToolbar.vue';
import TeachersMixin from '@/mixins/teachers-mixin';
import RosteredItemIndicator from '@/components/integration/RosteredItemIndicator.vue';
import { Component } from 'vue-property-decorator';
import SearchFilter from '@/components/core/SearchFilter.vue';
import CommonUtils from '@/utils/common-utils';

@Component({
  mixins: [TeachersMixin],
  components: {
    BaseToolbar,
    RosteredItemIndicator,
    SearchFilter
  }
})
export default class TeachersToolbar extends BaseToolbar {
  editMode!: boolean;
  searchText!: string;
  selectedRows!: Array<any>;
  hasSelectedRows!: boolean;
  searchWidthGrow = '0';

  $refs!: {
    searchFilter: SearchFilter
  }

  get arrayAddOrRemove() {
    return CommonUtils.arrayAddOrRemove;
  }

  get localTeachersComponent(): any {
    return this.currentPageInstance || {};
  }

  get toggleEditMode() {
    return this.localTeachersComponent.toggleEditMode || this.emptyFunction;
  }

  get doShowInviteTeachers() {
    return (
      this.localTeachersComponent.doShowInviteTeachers || this.emptyFunction
    );
  }

  get doShowJoinCodes() {
    return this.localTeachersComponent.doShowJoinCodes || this.emptyFunction;
  }

  get deleteTeachers() {
    return this.localTeachersComponent.deleteTeachers || this.emptyFunction;
  }

  get filterSelections() {
    return this.localTeachersComponent.filterSelections || ['invited'];
  }

  set filterSelections(val:any) {
    this.localTeachersComponent.filterSelections = val;
  }

  get showInviteTeachers() {
    return this.localTeachersComponent.showInviteTeachers;
  }

  get schoolItems() {
    return this.localTeachersComponent.schoolItems || [];
  }

  get currentSchoolId() {
    return this.localTeachersComponent.currentSchoolId;
  }

  set currentSchoolId(val:any) {
    this.localTeachersComponent.currentSchoolId = val;
  }

  get haveMultipleSchools():boolean {
    return this.localTeachersComponent.haveMultipleSchools || false;
  }

  get filterCount() {
    if (this.filterSelections.length === 1) {
      return 0;
    } else {
      return 1;
    }
  }

  get isFiltered() {
    if (this.filterCount === 0) {
      this.searchWidthGrow = '0';
      return false;
    } else {
      this.searchWidthGrow = '40';
      return true;
    }
  }

  clearFilter() {
    this.localTeachersComponent.filterSelections = ['invited'];
  }

  focusMenu() {
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        try {
          (this.$refs as any).firstMenuItem.$el.focus();
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      });
    });
  }

  focusButton() {
    this.$refs.searchFilter.focusButton();
  }
}
